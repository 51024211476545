module.exports = {
  siteTitle: 'KPC Architectural Products, Inc.',
  siteTitleAlt: 'KPC Architectural', // This allows an alternative site title for SEO schema.
  publisher: 'KPC Architectural Products, Inc.', // Organization name used for SEO schema
  siteDescription:
    'KPC Architectural Products, Inc. is a family owned and operated library and commercial interiors company, started in 1974, located in Lexington, Kentucky.',
  siteKeywords:
    'KPC Architectural Products, Inc., Interior Design, Lexington Ky',
  siteUrl: 'https://kpcarch.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  projectsPerPage: 12, // Number of posts shown on paginated pages
  author: 'KPC Architecture', // Author for RSS author segment and SEO schema
  authorUrl: 'https://kpcarch.com', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@twitter', // Change for Twitter Cards
  shortTitle: 'KPC Architectural Products, Inc. ', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 960, // Change to the width of your default share image
  shareImageHeight: 720, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  favicon: '/favicon/KPCfavicon.svg', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#2D62C3', // Used for Offline Manifest
  themeColor: '#2D62C3', // Used for Offline Manifest
  copyright: 'Copyright ©2019 KPC Architectural Products, Inc', // Copyright string for the RSS feed
}
