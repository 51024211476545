const color = {
  primary: '#2D62C3',
  secondary: '#E36650',
  blue: '#2D62C3',
  dlfBlue: '#7DACB6',
  orange: '#F07F20',
  yellow: '#E9D263',
  red: '#E36650',
  lightGrey: '#f7f7f7',
  grey: '#CBCFD7',
  black: '#222429',
  text: 'rgba(34,36,41,.7)',
  textMain: 'rgba(34,36,41,1)',
  white: '#ffffff',
  // white: 'pink',
}

const socialColor = {
  facebook           : 'hsla(222, 47%, 40%, 1)',     /* #365397 */
  twitter            : 'hsla(198, 100%, 47%, 1)',    /* #00a9f1 */
  linkedin           : 'hsla(203, 100%, 35%, 1)',    /* #006db3 */
  apple              : 'hsla(0, 0%, 45%, 1)',        /* #737373 */
  google             : 'hsla(217, 89%, 61%, 1)',     /* #4285f4 */
  google_plus        : 'hsla(8, 74%, 53%, 1)',       /* #e0452c */
  youtube            : 'hsla(0, 100%, 60%, 1)',      /* #ff3333 */
  vimeo              : 'hsla(200, 82%, 61%, 1)',     /* #48b6ed */
  pinterest          : 'hsla(0, 78%, 45%, 1)',       /* #ce1a19 */
  yelp               : 'hsla(5, 100%, 38%, 1)',      /* #c30f00 */
  dribbble           : 'hsla(338, 82%, 60%, 1)',     /* #ed4584 */
  amazon             : 'hsla(36, 100%, 50%, 1)',     /* #ff9700 */
  ebay               : 'hsla(219, 89%, 30%, 1)',     /* #083790 */
  skype              : 'hsla(198, 100%, 48%, 1)',    /* #00acf4 */
  instagram          : 'hsla(208, 46%, 41%, 1)',     /* #396d9a */
  dropbox            : 'hsla(206, 89%, 46%, 1)',     /* #0d84de */
  flickr             : 'hsla(334, 100%, 46%, 1)',    /* #ea0066 */
  github             : 'hsla(0, 0%, 18%, 1)',        /* #2f2f2f */
  basecamp           : 'hsla(121, 38%, 58%, 1)',     /* #6bbd6d */
  tumblr             : 'hsla(210, 37%, 30%, 1)',     /* #304c68 */
  foursquare         : 'hsla(206, 72%, 45%, 1)',     /* #207dc5 */
  box                : 'hsla(204, 83%, 40%, 1)',     /* #1277bc */
}

const font = {
  heading: 'jaf-domus',
  normal: 'Source Sans Pro,sans-serif',
}

const sizes = {
  xLarge: 1200,
  large: 992,
  medium: 768,
  small: 480,
  // maxWidth: '1050px',
  // maxWidthCentered: '650px',
}

function SpaceBase(base) {
  this.base = `${base}px`
  this.half = `${base * 0.5}px`
  this.forth = `${base * 0.25}px`
  this.double = `${base * 2}px`
  this.triple = `${base * 3}px`
  this.quad = `${base * 4}px`
}
const space = new SpaceBase(50)
const spacemobile = new SpaceBase(30)

const colors = {
  base: '#121212', // Black
  secondary: '#e9e9e9', // Medium Gray
  tertiary: '#f3f3f3', // Light Gray
  highlight: '#5b8bf7', // Light Blue
}

const responsive = {
  small: '35em',
  medium: '50em',
  large: '70em',
}


const theme = {
  color,
  socialColor,
  font,
  sizes,
  space,
  spacemobile,

  colors,
  responsive,
}

export default theme