import React , { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { slide as MenuNav } from 'react-burger-menu'

import theme from '../styles/theme'
import logo from "../images/kpc-logo.png"
// import logo from "../images/kpc-logo.svg"
import { media } from '../styles/utils'

const isPartiallyActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: "active" }
    : null
}


const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props} />
)

const activeLinkStyle = {
  color: theme.color.primary
}

const HeaderTop = styled.div`
  ${media.large`
    height: 80px;
  `}

  .logo {
    max-width: 300px;
    padding: 20px 0;
    
    img {
      max-height: 80px;
    }
    
    a {
      display: block;
    }
    ${media.large`
      line-height: 60px;
      padding: 10px 0;
     
     img {
        max-height: 60px;
      }
    `}
  }

  .navigation {
    padding-left: 0;
  }

  .header-space {
    ${media.large`
      height: 80px;
    `}
  }
`;

const MenuWrap = styled.div`
  display: none;

  ${media.large`
    display: block;
  `}
`;

export default class Menu extends Component {
  showSettings (event) {
    event.preventDefault();
  }
  render() {
    return (
    <>
      <header className="header-style-1">
        <MenuWrap>
          <MenuNav right width={ '360px' }>
            <Link to="/" className="menu-item">Home</Link>
            <Link to="/projects/" className="menu-item">Projects</Link>
            <Link to="/about/" className="menu-item">About</Link>
            <Link to="/partnerships/" className="menu-item">Partnerships</Link>
            <Link to="/blog/" className="menu-item">Blog</Link>
            <Link to="/contact/" className="menu-item">Contact Us</Link>
          </MenuNav>
        </MenuWrap>
        <HeaderTop className="header-top active-sticky light-bg">

            <div className="row">
              <div className="col-sm-12 col-md-3">
                <div className="left">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={logo}
                        alt="KPC Architectural Products, inc"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-9">
                <div className="right">

                  <nav className="mainmenu menu-hover-1 pull-right">
                    <div className="navbar-header">
                      {/* <button
                        type="button"
                        className="navbar-toggle"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                      >
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                      </button> */}
                    </div>
                    <div className="navbar-collapse collapse clearfix">
                      <ul className="navigation clearfix">
                        <li>
                          <Link to="/" activeClassName="active">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/projects/" activeClassName="active">Projects</Link>
                        </li>
                        <li>
                          <Link to="/about/" activeClassName="active">About</Link>
                        </li>
                        <li>
                          <Link to="/partnerships/" activeClassName="active">Partnerships</Link>
                        </li>
                        <li>
                          <PartialNavLink to="/blog/" activeClassName="active">Blog</PartialNavLink>
                        </li>
                        <li>
                          <Link to="/contact/" activeClassName="active">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

        </HeaderTop>
      </header>
      <div className="header-space bg-color-2" />
    </>
  )

  }
}