import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'

import favicon from '../images/favicon.ico'
import '../styles/css/bootstrap.min.css';
import '../styles/css/material-design-iconic-font.min.css';
// import '../styles/css/et-lineicons.min.css';
import '../styles/css/venobox.css';
import '../styles/css/slick.min.css';
import '../styles/css/responsive.css';
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Menu from '../components/Menu'
import Footer from '../components/Footer'

const Template = ({ children }) => {

  return (
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <>
        <GlobalStyle />
          <div className="siteContent">
            <Menu />
            {children}
          </div>
          <Footer />
        </>
        
      </ThemeProvider>
      
    </div>
  )
}

export default Template
