import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import logo from "../images/kpc-logo-white.png"
import WomensOrgImgSrc from "../images/WBE_Seal_RGB.gif"

const currentYear = new Date().getFullYear();

const FooterWrap = styled.footer`
  ul,li {
    list-style: none;
  }

  ul {
    padding-left: 0;
  }
`;

const Footer = () => (
  <FooterWrap>
    <div className="footer-widget-area drak-bg ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-3 col-md-3 mobi-mb-30">
            <div className="footer-widget about">
              <div className="title mb-20">
                <Link to="/" activeClassName="active">
                  <img
                    src={logo}
                    alt="KPC Architectural Products, inc"
                  />
                </Link>
              </div>
              
              <img src={WomensOrgImgSrc} alt="" width="200" />
              
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-2 mobi-mb-30">
            <div className="footer-widget address">
              <div className="title mb-20"><h4>Navigation</h4></div>
              <ul className="navigation clearfix">
                <li>
                  <Link to="/" >
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/projects/" >Projects</Link>
                </li>
                <li>
                  <Link to="/about/" >About</Link>
                </li>
                <li>
                  <Link to="/partnerships/" >Partnerships</Link>
                </li>
                <li>
                  <Link to="/blog/" >Blog</Link>
                </li>
                <li>
                  <Link to="/contact/" >Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-4 mobi-mb-30">
            <div className="footer-widget address">
              <div className="title mb-20"><h4>Contact Us</h4></div>
              <ul>
                <li>
                  <span>Phone: </span>
                  <a href="tel:+8592693646">859-269-3646</a>
                </li>
                <li>
                  <span>Fax: </span>
                  859-269-3648
                </li>
                <li>
                  <span>Email: </span>
                  <a href="mailto:personalinfo@mail.com">info@kpcarch.com</a>
                </li>
                <li className="mb-10">
                  <span>Address: </span>2464 Fortune Drive Suite 150<br />
                  Lexington, KY 40509
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-3">
            <div className="footer-widget social-media">
              <div className="title mb-20"><h4>Social Networks</h4></div>
              <div className="social-link">
                <ul className="clearfix d-inblock">
                  <li>
                    <a
                      href="https://www.facebook.com/KpcArchitecturalProductsInc/"
                      target="_blank"><i className="fab fa-facebook"/> Facebook</a>
                  </li>
                  {/* <li>
                    <a href="#" target="_blank"><i className="fab fa-twitter" /> Twitter</a>
                  </li> */}
                  <li>
                    <a href="https://www.instagram.com/kpcarchproducts/" target="_blank"><i className="fab fa-instagram"/> Instagram</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright black-bg ptb-15">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="text">
              <p>
                &copy; KPC Architectural Products, Inc. {currentYear}.  <Link to="/privacy/">Privacy</Link> | <Link to="/terms/">Terms &amp; Conditions</Link>
                <br />Website By <a href="http://allday.io" rel="noopener noreferrer">AllDay</a>. Powered by <a href="https://www.contentful.com/" rel="noopener noreferrer">Contentful</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FooterWrap>
)

export default Footer
